import { Component, ViewChild } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {
  logo_url = environment.S3_URL + 'motus9/logo.png';
  logo = environment + '../../../../assets/images/Picture.png';
  questions: any;
  member_id: any;
  form_datas: any = [];
  user_id: string;
  baselineintake: any;
  user: any;
  user_type: any;
  valueOneKey: string;
 
  
 
  constructor(
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private router :Router,
  ) {

  }
  userId: number;
  someParam: string;

  max: number = 5;
  min: number = 0;
  step: number = 1;
  value: number = 1; // This sets the default value to 1

  stepValues: number[] = [];
 

  ngOnInit() {
    this.generateStepValues();
    this.route.paramMap.subscribe(params => {
      const encryptedId = params.get('id');
      this.user_id = params.get('id');
      this.member_id = encryptedId;
    });
    this.checkId();
  
  }
   
  checkId(){
    // this.spinner.show();
  
    this.ApiService.checkId(this.member_id).subscribe((response: any) => {
      if (response.data.success) {
        this.user = response.data.data;
        this.user_type =  this.user;
        this.getQuestions( this.user_type);
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    }, (error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
    });
  }
  getQuestions(user_type :any) {
    // this.spinner.show();
    this.ApiService.getQuestion(user_type).subscribe((response: any) => {
      if (response.data.success) {
        this.baselineintake = response.data.data;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    }, (error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
    });
  }
  
  getRange(n: number): number[] {
    return Array.from({ length: n }, (_, index) => index);
 }

  onSubmit(question_form: any){
   let data =  question_form.value;
      let array = []
      Object.keys(data).forEach(key => {
      if(data[key]!== '') { 
        // if(this.user_type == 'member'){
          if(array?.[array.length-1]?.question == key?.split('.')[2]){
             array[array.length-1].answer = [...array[array.length-1]?.answer, data[key]]
          }else if(key?.split('.')[3] && array?.[array.length-1]?.question == key?.split('.')[3]){
            array[array.length-1].answer = [...array[array.length-1]?.answer, data[key]]
            // array[array.length-1].answer = [`${data[key]}/n${array[array.length-1]?.answer }`]
          }
          else{
            if(!key?.split('.')[2] && key?.split('.')[3]){
              array.push({"question":key?.split('.')[3], "answer":[data[key]]})
            }else if((!key?.split('.')[2] && !key?.split('.')[3])){
              array.push({"question":key, "answer":[data[key]]})
            }
            else{
              array.push({"question":key?.split('.')[2], "answer":[data[key]]})
            }
           
          }
        }
        // else{
        //   console.log("===============else==============")
        //   array.push({"question":key, "answer":[data[key]]})
        // }
        
      // }
    });
    this.form_datas = array
    this.ApiService.createBaselineIntakeFormAns(this.member_id,this.form_datas).subscribe((response: any) => {
      if (response.data.success) {
        this.router.navigate(['/Success']);
      } else {
        this.toastr.warning(response.data.message);
      }
      this.spinner.hide();

    }, (error) => {
      if(error.response.data.message == "Invalid initialization vector || Assignment to constant variable."){
        this.toastr.warning("Invalid user ID");
        this.spinner.hide();
      }else{
        this.toastr.error("Something went wrong");
        this.spinner.hide();
      }
    });
  }

  generateStepValues() {
    for (let i = this.min; i <= this.max; i += this.step) {
      this.stepValues.push(i);
    }
  }

  getLeftPosition(value: number): number {
    const range = this.max - this.min;
    return ((value - this.min) / range) * 100;
  }

  onSliderChangeOne(event: any) {
    console.log("event==>",  event)
    // if(event.target.id == 0){
    //   this.valueOneKey = event.target.question;
    //   this.valueOne = event.target.value;
    // }
    // if(event.target.id == 1){
    //   this.valueTwo = event.target.value;
    // }
    // if(event.target.id == 2){
    //   this.valueThree = event.target.value;
    // }
    // if(event.target.id == 3){
    //   this.valueFour = event.target.value;
    // }
    // if(event.target.id == 4){
    //   this.valueFive = event.target.value;
    // }
    // if(event.target.id == 5){
    //   this.valuesix = event.target.value;
    // }
    // if(event.target.id == 6){
    //   this.valueSeven = event.target.value;
    // }
    // if(event.target.id == 7){
    //   this.valueEight = event.target.value;
    // }
    // if(event.target.id == 8){
    //   this.valueNine = event.target.value;
    // }
   
  }

}
