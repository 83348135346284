<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous">
<script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
<div class="app-content content">
    <div class="content-wrapper p-1 cwaper">
        <div class="content-body">

            <section id="configuration" class="set1408">
                <div class="container p-0 col-12">
                    <div class="card rounded-3">
                        <div class="action-container d-flex flex-wrap align-items-center justify-content-end mb-0 pl-0 p-1">
                            <div class="action-item mr-2 mb-2 mb-md-0">
                              <label class="btn btn-sm text-wrap fs-6 width-full  rounded-3 m-0 color-custom"  (change)="onFileSelected($event,'csv')" accept=".csv">
                                <i class="la la-upload" style="margin-right: 3px;"></i> Upload CSV
                                <input type="file" class="custom-file-input" id="importFile" style="display: none;">
                              </label>
                            </div>
                            <div class="action-item mr-2 mb-2 mb-md-0">
                              <button type="button" class="btn btn-sm text-wrap color-custom fs-6 width-full  rounded-3"(click)="importClients()">
                                  <i class="la la-sign-in" style="margin-right: 3px;"></i> Import
                              </button>
                            </div>
                            <div class="action-item mr-2 mb-2 mb-md-0  rounded-3"> 
                              <a  class="btn  btn-sm width-full rounded-3 color-custom" [href]="clients_excel_sample">
                                  <i class="la la-cloud-download" style="margin-right: 3px;"></i> Sample Coaches
                              </a>
                            </div>
                           <div class="action-item mr-2 mb-2 mb-md-0">
                            <select  style="height: 33px;"  id="Organization" class="form-control form-control-sm" (change)="filterClient()" [(ngModel)]="filter_org">
                                <option disabled>--Organization--</option>
                                <option value="0">All</option>
                                <option *ngFor="let o of all_organizations" [value]="o.id">{{o.name}}</option>
                            </select>
                           </div>
        
                            <div class="action-item mr-2 mb-2 mb-md-0">
                                <button type="button" class="btn btn-sm text-wrap fs-6 width-full  rounded-3 color-custom" (click)="openCreateForm(Add)"> 
                                    <i class="la la-user-plus" style="margin-right: 3px;"></i> Add Client
                                </button>
                            </div>
                            <div class="action-item mb-2 mb-md-0">
                                <div class="search-container">
                                    <input type="text" style="border: #12343b;" class="form-control lh-base fs-6  rounded-3 shadow-lg " placeholder="Search......" (keyup)='updatedefaultFilter($event)'>
                                    <i class="la la-search search-icon"></i>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <!-- <div class="card py-1 px-2">
                        <ul class="d-flex flex-wrap align-items-center mb-0 pl-0" style="list-style: none;">
                            <li class="mr-1 li-min-width" >
                                    <div style="height: 33px;" class="custom-file" style="font-size: 1rem;">
                                        <input  type="file" class="custom-file-input" id="importFile" (change)="onFileSelected($event,'csv')" accept=".csv">
                                        <label style="    height: 34px !important;  margin-top: 4px;" class="custom-file-label mb-0" for="inputGroupFile01">Upload CSV</label>
                                    </div>
                            </li>
                            <li class="mr-1 li-min-width" >
                                <button  style="height: 33px;"  type="button" class="btn btn-outline-dark  btn-sm width-full" (click)="importClients()">Import</button>
                            </li>
                            <li class="mr-1 li-min-width" >
                                <button  style="height: 33px;"  type="button" class="btn btn-outline-dark  btn-sm width-full" (click)="openCreateForm(Add)"> Add Client</button>
                            </li>
                            <li class="mr-1 li-min-width" >
                                <a  style="height: 33px;"  [href]="clients_excel_sample" class="btn btn-outline-dark btn-sm width-full">
                                    <i class="la la-cloud-download"></i> Sample Clients
                                </a>          
                            </li>
                            <li class="mr-1 li-min-width" >
                                <div class="dataTables_filter" style="text-align: left !important;">
                                    <input  style="height: 33px;"  type="text" [(ngModel)]="searchQuery" (input)="updateFilteredClients()" placeholder="Search">
                                </div>
                            </li>
                            <li class="mr-1 li-min-width" >
                                <select  style="height: 33px;"  id="Organization" class="form-control form-control-sm" (change)="filterClient()" [(ngModel)]="filter_org">
                                    <option disabled>--Organization--</option>
                                    <option value="0">All</option>
                                    <option *ngFor="let o of all_organizations" [value]="o.id">{{o.name}}</option>
                                </select>
                            </li>
                        </ul>
                    </div> -->
                    <!-- <div class="card py-1 px-2">
                        <div class="row " style="gap: 5px;">
                            <div class="col-sm-3">
                                <div class="form-group my-auto">
                                    <div class="custom-file" style="font-size: 1rem;">
                                        <input type="file" class="custom-file-input" id="importFile" (change)="onFileSelected($event,'csv')" accept=".csv">
                                        <label class="custom-file-label" for="inputGroupFile01">Upload CSV</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <button type="button" class="btn btn-outline-dark  btn-sm width-full" (click)="importClients()">Import</button>
                            </div>
                            <div class="col-sm-2">
                                <button type="button" class="btn btn-outline-dark  btn-sm width-full" (click)="openCreateForm(Add)"> Add Client</button>
                            </div>
                            <div class="col-sm-2">
                                <a [href]="clients_excel_sample" class="btn btn-outline-dark btn-sm width-full">
                                    <i class="la la-cloud-download"></i> Sample Clients
                                </a>
                            </div>
                            <div class="col-sm-2">
                                <div class="dataTables_filter" style="text-align: left !important;">
                                    <input type="text" [(ngModel)]="searchQuery" (input)="updateFilteredClients()" placeholder="Search">
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <select id="Organization" class="form-control form-control-sm" (change)="filterClient()" [(ngModel)]="filter_org">
                                <option disabled>--Organization--</option>
                                <option value="0">All</option>
                                <option *ngFor="let o of all_organizations" [value]="o.id">{{o.name}}</option>
                            </select>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="container custom-container p-0 col-12">
                    <table class="table table-striped  table-font table-font-size-14" style="font-size: 14px;">
                        <thead>
                            <tr >
                                <!-- Your table headers -->
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="id-width">Id</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="image-width">Image</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Name</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="padding-l wid-client-mob-orgina">Mobile</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="email-width status-log-email  " >Email</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="padding-l wid-client-mob-orgina">Client Type</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="wid-client-mob-orgina ">Organization</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Last Login</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Status</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf=(this.filteredClients)>
                            <tr class="text-align: center;" *ngFor="let row of filteredClients; let i = index">
                                <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                <td class=" image-width">
                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                                </td>
                                <td class="status-log-email">{{ row.first_name }} {{row.last_name}}</td>
                                <td  class="padding-l wid-client-mob-orgina">{{ row.mobile }}</td>
                                <td class=" padding-l email-width status-log-email wid-client-mob-orgina " style="padding-right: 5px !important;">{{ row.email }}</td>
                                <td class="padding-l wid-client-mob-orgina">{{ row.client_type }}</td>
                                <td class="wid-client-mob-orgina">{{ row.Organization.name}}</td>
                                <td class="status-log-email padding-l"> {{row.last_login | datetime:'DD-MMM-YY'}}</td>
                                <td class="status-log-email">  <select [value]="row.status" (change)="updateUserStatus(row.user_id)" [disabled]="row.access_level == 'admin' || row.org_id == motus_org_id" [id]="'status_'+row.id">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    </select></td>
                                <td>
                                    <i class="la la-envelope text-success hoverclass" (click)="sendMail(row.user_id,row.email,row.mobile)" aria-label="Email"></i>
                                    <button type="button" [disabled]="row.baselineIntakeForm == null" (click)="editClient(row.id, baselineintakeForm)" class="btn btn-sm" style="border: 0px !important;"> 
                                        <span [style.cursor]="row.baselineIntakeForm == null ? 'not-allowed' : 'pointer'">
                                            <i *ngIf="row.baselineIntakeForm == null" class="la la-file-text  text-light font-weight-bold newclass"></i>
                                            <i *ngIf="row.baselineIntakeForm != null" class="la la-file-text font-weight-bold newclass icon-success"></i> 
                                        </span>
                                    </button>
                                    <i (click)="editClient(row.id,Edit)" *ngIf="row.org_id != motus_org_id" class="la la-pencil-square-o text-success hoverclass mr-1" aria-hidden="true"></i>
                                    <i *ngIf="row.org_id == motus_org_id" class="la la-pencil-square-o text-light mr-1" aria-hidden="true"></i>

                                    <i (click)="deleteUser(row.user_id)" *ngIf="row.org_id != motus_org_id" class="la la-trash text-danger hoverclass mr-1" aria-hidden="true"></i>

                                    <i class="la la-trash text-light mr-1" *ngIf="row.org_id == motus_org_id" aria-hidden="true"></i>
                                    <img src="assets/images/FFD.png" alt="image" style="width: 30px; height:15px;" (click)="redirectFfd(row);openCreateForm(ClientFfd)" class="hoverclass" />
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="this.showTable == true" >
                            <tr class="text-align: center;" *ngFor="let row of this.clients | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index">
                                <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                <td class=" image-width">
                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                                </td>
                                <td class="status-log-email">{{ row.first_name }} {{row.last_name}}</td>
                                <td  class="padding-l wid-client-mob-orgina">{{ row.mobile }}</td>
                                <td class=" padding-l email-width status-log-email wid-client-mob-orgina " style="padding-right: 5px !important;">{{ row.email }}</td>
                                <td class="padding-l wid-client-mob-orgina">{{ row.client_type }}</td>
                                <td class="wid-client-mob-orgina">{{ row.Organization.name}}</td>
                                <td class="status-log-email padding-l">  {{row.last_login | datetime:'DD-MMM-YY'}}</td>
                                <td class="status-log-email">  <select [value]="row.status" (change)="updateUserStatus(row.user_id)" [disabled]="row.access_level == 'admin' || row.org_id == motus_org_id" [id]="'status_'+row.id">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    </select></td>
                                <td>
                                    <i class="la la-envelope text-success  hoverclass" (click)="sendMail(row.user_id,row.email,row.mobile)" aria-label="Email"></i>
                                    <button type="button" [disabled]="row.baselineIntakeForm == null" (click)="editClient(row.id, baselineintakeForm)" class="btn btn-sm" style="border: 0px !important;"> 
                                        <span [style.cursor]="row.baselineIntakeForm == null ? 'not-allowed' : 'pointer'">
                                            <i *ngIf="row.baselineIntakeForm == null" class="la la-file-text  text-light font-weight-bold newclass"></i>
                                            <i *ngIf="row.baselineIntakeForm != null" class="la la-file-text  font-weight-bold newclass icon-success"></i> 
                                        </span>
                                    </button>
                                    <i (click)="editClient(row.id,Edit)" *ngIf="row.org_id != motus_org_id" class="la la-pencil-square-o text-success hoverclass mr-1" aria-hidden="true"></i>
                                    <i *ngIf="row.org_id == motus_org_id" class="la la-pencil-square-o text-light mr-1" aria-hidden="true"></i>

                                    <i (click)="deleteUser(row.user_id)" *ngIf="row.org_id != motus_org_id" class="la la-trash text-danger hoverclass mr-1" aria-hidden="true"></i>

                                    <i class="la la-trash text-light mr-1" *ngIf="row.org_id == motus_org_id" aria-hidden="true"></i>
                                    <img src="assets/images/FFD.png" alt="image" style="width: 30px; height:15px;" (click)="redirectFfd(row);openCreateForm(ClientFfd)" class="hoverclass" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- Pagination -->
                     <div  class="row " style="background: #727e8e;color: #ededed; margin: 0px !important;">
                        <div class="col-6">
                            <h6 class="total-entries text-style" style="color: #ededed">Total Entries: {{ totalItems }}</h6>
                        </div>
                        <div *ngIf="this.showTable == true" class="col-6" style="justify-content: end;display: grid;">
                            <ngb-pagination
                            [(page)]="currentPage"
                            [pageSize]="itemsPerPage"
                            [collectionSize]="totalItems"
                            (pageChange)="onPageChange($event)"
                            aria-label="Default pagination"
                            class="custom-pagination"
                          ></ngb-pagination>
                        </div>
                    </div>
                </div>
            </section>
            <section id="form-components">
                <!-- Modal -->
                <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Add Client</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange();clearData();">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="userInfo" (ngSubmit)="onProjectInfoSubmit()" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                          <div *ngIf=" f.first_name.errors.required">
                            First Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" formControlName="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                          <div *ngIf="f.last_name.errors.required">
                            Last Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput3">E-mail *</label>
                                                <input type="text" id="projectinput3" class="form-control" formControlName="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email
                            is required</div>
                          <div *ngIf="f.email.errors.email">Email must
                            be a valid email address</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" formControlName="mobile" placeholder="Mobile No." [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" (keypress)="keyPress($event)" maxlength="12" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">Mobile No.
                            is required</div>
                        </small>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Client Type*</label>
                                                <select id="projectinput5" class="form-control" formControlName="client_type" [ngClass]="{ 'is-invalid': submitted && f.client_type.errors }">
                          <option disabled>--Select--</option>
                          <option value="member">Member</option>
                          <option value="owner" selected>Owner</option>
                        </select>
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.client_type.errors" class="invalid-feedback">
                          <div *ngIf="f.client_type.errors.required">
                            Client type is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput6">Organization*</label>
                                                <select id="projectinput6" class="form-control" formControlName="org_id" [ngClass]="{ 'is-invalid': submitted && f.org_id.errors }">
                          <option disabled>--Select--</option>
                          <option *ngFor="let o of organizations" [value]="o.id">{{o.name}}</option>
                        </select>
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.org_id.errors" class="invalid-feedback">
                          <div *ngIf="f.org_id.errors.required">
                            Organization is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                      <div class="form-group">
                        <label for="projectinput7">Password *</label>
                        <input type="password" id="projectinput7" class="form-control" formControlName="password"
                          placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password
                            is required</div>
                        </small>
                      </div>
                    </div> 
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="projectinput8">Confirm Password *</label>
                        <input type="password" id="projectinput8" class="form-control" formControlName="password_confirmation"
                          placeholder="Confirm Password " [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" />
                        <small class="form-text text-muted danger" *ngIf="submitted && f.password_confirmation.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.password_confirmation.errors.required">Password Confirmation
                            is required</div>
                        </small>
                      </div>
                    </div> -->

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" id="title" class="form-control" formControlName="title" placeholder="Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors" class="invalid-feedback">
                          <div *ngIf="f.title.errors.required">Title
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="division">Department</label>
                                                <input type="text" id="division" class="form-control" formControlName="division" placeholder="Department" [ngClass]="{ 'is-invalid': submitted && f.division.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.division.errors" class="invalid-feedback">
                          <div *ngIf="f.division.errors.required">Department
                            is required</div>

                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="reportees">Reportees</label>
                                                <input type="text" id="reportees" class="form-control" formControlName="reportees" placeholder="Reportees" [ngClass]="{ 'is-invalid': submitted && f.reportees.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.reportees.errors" class="invalid-feedback">
                          <div *ngIf="f.reportees.errors.required">Reportees
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="dob">Date of birth</label>
                                                <input type="date" id="dob" class="form-control" formControlName="dob" placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" [max]="eighteen_y_before" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                          <div *ngIf="f.dob.errors.required">Date of birth
                            is required</div>

                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="work_anniversary">Work anniversary</label>
                                                <input type="date" id="work_anniversary" class="form-control" formControlName="work_anniversary" placeholder="Work anniversary" [ngClass]="{ 'is-invalid': submitted && f.work_anniversary.errors }" [min]="today_date" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.work_anniversary.errors" class="invalid-feedback">
                          <div *ngIf="f.work_anniversary.errors.required">Work anniversary
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput10" class="file center-block">
                        <input type="file" id="file" formControlName="image"
                          [ngClass]="{ 'is-invalid': submitted && f.image.errors }" style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                   <div *ngIf="f.image.errors.required">Image
                                     is required</div>
                                 </small> -->
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" [alt]="userInfo.value.name" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- multi org work -->
                                <!-- <div class="form-body" *ngIf="user_exists">
                  <div class="row justify-content-start">
                    <h5>These profiles already exists with provided credentials, Please select one or change email/password</h5>
                    <div class="col-sm-4" *ngFor="let u of existing_user;let i = index">
                      <div class="card p-1">
                        <div class="row">
                          <div class="col-1">
                            <input type="radio" id="ex_client_id" [value]="u.client_id" formControlName="ex_client_id" >
                          </div>
                          <div class="col-11">
                            First Name: {{ u.first_name}} <br>
                            Last Name: {{ u.last_name}} <br>
                            Email: {{ u.email}} <br>
                            Mobile: {{ u.mobile}} <br>
                            Oranization: {{ u.org_name}} <br>
                          </div>
                        </div>
                      </div>
                
                    </div>
                  </div>
                </div> -->
                                <!-- End multi org work -->
                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange();clearData()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </ng-template>

                <ng-template class="modal text-left" #Edit let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Edit Client</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <form #edit_client_form="ngForm" (ngSubmit)="updateClient(client,client.user_id)" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" placeholder="First Name" [(ngModel)]="client.first_name" name="first_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors"
                          class="invalid-feedback">
                          <div *ngIf=" f.first_name.errors.required">
                            First Name is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" placeholder="Last Name" [(ngModel)]="client.last_name" name="last_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.last_name.errors.required">
                            Last Name is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" placeholder="E-mail" [(ngModel)]="client.email" name="email" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email
                            is required</div>
                          <div *ngIf="f.email.errors.email">Email must
                            be a valid email address</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" placeholder="Mobile No." (keypress)="keyPress($event)" [(ngModel)]="client.mobile" name="mobile" maxlength="12" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">Mobile No.
                            is required</div>
                        </small> -->
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Client Type*</label>
                                                <select id="projectinput5" class="form-control" name="client_type" [ngClass]="{ 'is-invalid': submitted && f.client_type.errors }" [(ngModel)]="client.client_type">
                          <option disabled>--Select--</option>
                          <option value="member">Member</option>
                          <option value="owner" selected>Owner</option>
                        </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.client_type.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.client_type.errors.required">
                            Client type is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Organization*</label>
                                                <select id="projectinput5" class="form-control" name="org_id" [(ngModel)]="client.org_id">
                          <option disabled>--Select--</option>
                          <option *ngFor="let o of organizations" [value]="o.id">{{o.name}}</option>
                        </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.org_id.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.org_id.errors.required">
                            Organization is required</div>
                        </small> -->
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" id="title" class="form-control" placeholder="Title" [(ngModel)]="client.title" name="title" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.title.errors.required">Title
                            is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="division">Department</label>
                                                <input type="text" id="division" class="form-control" name="division" placeholder="Department" [(ngModel)]="client.division" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.division.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.division.errors.required">Department
                            is required</div>

                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="reportees">Reportees</label>
                                                <input type="text" id="reportees" class="form-control" placeholder="Reportees" [(ngModel)]="client.reportees" name="reportees" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.reportees.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.reportees.errors.required">Reportees
                            is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="dob">Date of birth</label>
                                                <input type="date" id="dob" class="form-control" placeholder="Date of birth" [(ngModel)]="client.dob" name="dob" [max]="eighteen_y_before" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.dob.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.dob.errors.required">Date of birth
                            is required</div>

                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="work_anniversary">Work anniversary</label>
                                                <input type="date" id="work_anniversary" class="form-control" placeholder="Work anniversary" [(ngModel)]="client.work_anniversary" name="work_anniversary" [min]="today_date" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.work_anniversary.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.work_anniversary.errors.required">Work anniversary
                            is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Status*</label>
                                                <select id="projectinput5" class="form-control" name="status" [(ngModel)]="client.status" [disabled]="client.access_level == 'admin' ">
                          <option disabled>--Select--</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.status.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.status.errors.required">
                            Status is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" 
                          style="margin-left: 3px;" (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.image.errors.required">Image
                          is required</div>
                      </small> -->
                                            <div class="col-4" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                                                <img [src]="imageURL" alt="client" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>


                                </div>
                                <!-- <button type="submit" class="btn btn-primary">
                               <i class="la la-check"></i> Submit
                             </button> -->

                            </form>
                        </div>

                    </div>
                </ng-template>

                <ng-template class="modal text-left" #ClientFfd let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23" class="font-weight-bold ">FFD</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');clearFfd()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card">

                                        <div class="text-center">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px;height: 165px; background-color: rgba(0, 0, 0, 0.06); ">
                                                            <div class="card-content">
                                                                <div class="card-body" style="padding: 0.5rem;">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <h4 class="text-center">Progress</h4>
                                                                            <h6 style="color:white">
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="targetCount.progress" [radius]="25" [showTitle]="true" [outerStrokeWidth]="4" [innerStrokeWidth]="1" [outerStrokeColor]="'red'" [innerStrokeColor]="'white'" [showUnits]="true" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px;height: 50px; background-color: rgba(0, 0, 0, 0.06);" (click)="current_ffd = 'Target'; current_ffd_data = target">
                                                            <div class="card-content">
                                                                <div class="card-body" style="padding: 0.5rem;">
                                                                    <div class="media d-flex">
                                                                        <img src="assets/images/Group3.png" alt="image" style="width: 20px; height: 20px;margin-left: 7px;margin-top: 10px;" class="" />&nbsp;&nbsp;&nbsp;
                                                                        <div class="media-body text-left" style="border-left:1px solid #ce4244;">&nbsp;&nbsp; Target
                                                                            <br>&nbsp;&nbsp;&nbsp;{{targetCount.total}}

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card" style="border-radius: 20px;height: 50px; background-color: rgba(0, 0, 0, 0.06);">
                                                            <div class="card-content">
                                                                <div class="card-body" style="padding: 0.5rem ;">
                                                                    <div class="media d-flex">
                                                                        <img src="assets/images/Group2.png" alt="image" style="width: 20px; height: 20px;margin-left: 7px;margin-top: 10px;" class="" />&nbsp;&nbsp;&nbsp;
                                                                        <div class="media-body text-left" style="border-left:1px solid #ce4244;">&nbsp;&nbsp; In Progress
                                                                            <br>&nbsp;&nbsp;&nbsp;{{targetCount.in_progress}}


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card" style="border-radius: 20px;height: 50px; background-color: rgba(0, 0, 0, 0.06);">
                                                            <div class="card-content">
                                                                <div class="card-body" style="padding: 0.5rem ;">
                                                                    <div class="media d-flex">
                                                                        <img src="assets/images/Group1.png" alt="image" style="width: 20px; height: 20px;margin-left: 7px;margin-top: 10px;" class="" />&nbsp;&nbsp;&nbsp;
                                                                        <div class="media-body text-left" style="border-left:1px solid #ce4244;">&nbsp;&nbsp; Achieved
                                                                            <br>&nbsp;&nbsp;&nbsp;{{targetCount.achieved}}


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px; background-color:#a2a79d" (click)="current_ffd = 'Elephants'; current_ffd_data = elephants">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/Elephant.png" alt="image" style="width: 15px; height: 15px;" />
                                                                            <h4 style="color: #FFFFFF;">Elephants </h4>
                                                                            <h6 style="color: #FFFFFF;">{{elephantCount.is_done}}/{{elephantCount.total}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="elephantCount.percent" [radius]="10" [outerStrokeWidth]="1" [innerStrokeWidth]="1" [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#a2a79d'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px; background-color: #ce4244;" (click)="current_ffd = 'Blindspots'; current_ffd_data = blindspots">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/ActionItem.png" alt="image" style="width: 15px; height: 15px;" />
                                                                            <h4 style="color: #FFFFFF;">Blindspots </h4>
                                                                            <h6 style="color: #FFFFFF;">
                                                                                {{blindspotCount.is_done}}/{{blindspotCount.total}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="blindspotCount.percent" [radius]="10" [outerStrokeWidth]="1" [innerStrokeWidth]="1" [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#ce4244'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px; background-color: #3d4232" (click)="current_ffd = 'Action Items'; current_ffd_data = action_items">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/ActionItem.png" alt="image" style="width: 15px; height: 15px;" />
                                                                            <h4 style="color: #FFFFFF;">Action Items</h4>
                                                                            <h6 style="color: #FFFFFF;">
                                                                                {{actionItemCount.is_done}}/{{actionItemCount.total}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="actionItemCount.percent" [radius]="10" [outerStrokeWidth]="1" [innerStrokeWidth]="1" [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#3d4232'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px; background-color: #f3efef" (click)="current_ffd = 'Takeaways'; current_ffd_data = take_aways">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/TakeAway.png" alt="image" style="width: 15px; height: 15px;" />
                                                                            <h4 style="color: #CF4144;">Takeaways </h4>
                                                                            <h6 style="color: #CF4144;">{{takeAwayCount.total}}</h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hover-hand hoverclass" style="border-radius: 20px; background-color: #ce4244;" (click)="current_ffd = 'Sessions';current_ffd_data = null">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <i class="feather ft-eye h4" style="color: #FFFFFF;"></i>
                                                                            <h4 style="color: #FFFFFF;">Sessions</h4>
                                                                            <h6 style="color: #FFFFFF;">
                                                                                {{completed}}/{{total_sessions}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="percent_sess" [radius]="10" [outerStrokeWidth]="1" [innerStrokeWidth]="1" [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#ce4244'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card" *ngIf="(current_ffd_data != null && current_ffd_data.length > 0) || ( current_ffd == 'Sessions')">
                                        <h4 class="card-title m-1">
                                            {{current_ffd}}
                                        </h4>
                                        <div style="display: flex; margin-bottom: 15px !important;" *ngIf="current_ffd == 'Sessions'"><div style="border-radius: 10px;background-color: #2E8A57;width: 10px; height: 11px; margin-top: 5px;margin-left: 13px;"> </div><div style="margin-left: 3px;">Completed</div><div style="border-radius: 10px; background-color: #CF4144;width: 10px; height: 11px;margin-top: 5px; margin-left: 10px;"></div><div style="margin-left: 3px; ">Missed</div></div>
                                        <div class="text-center m-0" style="margin: 0px !important;padding: 0px !important;">
                                            <div class="card-body m-0" style="margin: 0px !important;padding: 0px !important;">
                                                <div id="new-orders" class="media-list position-relative m-0 tbodyDiv">
                                                    <div class="table-responsive" *ngIf="current_ffd !== 'Sessions' ">
                                                        <table id="new-orders-table" class="table table-hover  mb-0 order-tbl_change d-block" style="height: 55vh;overflow: scroll;">
                                                            <thead>
                                                                <tr>
                                                                    <th class="border-top-0 text-left">Name</th>

                                                                    <th class="border-top-0 text-left" *ngIf="current_ffd !== 'Takeaways' &&  current_ffd !== 'Target' ">Done(Yes/No)</th>
                                                                    <th class="border-top-0 text-left" *ngIf="current_ffd === 'Target' ">Completed</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let row of current_ffd_data">
                                                                    <td class="text-truncate text-left">{{row.name}}</td>

                                                                    <td class="text-truncate text-left" *ngIf="current_ffd !== 'Takeaways' &&  current_ffd !== 'Target' ">
                                                                        <input type="checkbox" [checked]="row.is_done == '1'" disabled>
                                                                    </td>
                                                                    <td class="text-truncate text-left" *ngIf="current_ffd === 'Target' ">{{row.completed}} %
                                                                    </td>

                                                                </tr>

                                                            </tbody>

                                                        </table>

                                                    </div>
                                                    <div class="table-responsive" *ngIf="current_ffd == 'Sessions' && getsessions != null && getsessions.length > 0" style="margin-top: -10px;">
                                                        <table id="recent-orders" class="table  mb-0">
                                                            <tbody>
                                                                <tr *ngFor="let row of getsessions">
                                                                    <!-- <td class="p-1">
                                                                        <div class="session-status" [ngClass]="{'missied-session': row.status === 'missed', 'completed-session': row.status === 'completed'}">
                                                                            <div class="text-bold-800" style="border-radius: 10px;margin-left: -30px; height: 88px; background-color: #CDCDCD; padding: 15px;">
                                                                              
                                                                            </div>
                                                                        </div>
                                                                    </td> -->
                                                                    
                                                                    <td class="p-1">
                                                                        <div class=" text-bold-800 " style="border-radius: 10px;height: 88px;margin-left: -30px; background-color: white; padding: 5px;" [class.missied-session]="row.current_status == 'missed' " [class.completed-session]="row.current_status == 'completed' "></div>
                                                                    </td>
                                                                    <td class="p-1">
                                                                        <!-- <div class=" text-bold-800 " style="border-radius: 10px; background-color: #CDCDCD; padding: 5px; color: white; font-size: 27px;padding-top: 5px;" [class.missied-session]="row.status == 'missed' " [class.completed-session]="row.status == 'completed' "> -->
                                                                        <div class=" text-bold-800 " style="border-radius: 10px; background-color: #CDCDCD; padding: 5px; color: white; font-size: 27px;padding-top: 5px;">
                                                                            {{(row.session_date + ' ' + row.session_time) | datetime:'DD'}}<br> {{ (row.session_date + ' ' + row.session_time) | datetime:'MMM'}}</div>
                                                                    </td>
                                                                    <td class="text-start" style="padding-left: 5px;">
                                                                        <h6 style="margin-bottom: 0px; margin-top: 2px;"> {{row.session_with |titlecase}}</h6>
                                                                        <h6 style="margin-bottom: 0px;margin-top: 5px;"> {{row.title |titlecase}}</h6>
                                                                        <h6 style="margin-bottom: 0px;margin-top: 5px;"> {{ (row.session_date + ' ' + row.session_time) |datetime|uppercase}}</h6>
                                                                    </td>
                                                                    <td class=" p-1 border-top-0">

                                                                        <div class="avatar avatar-md mt-2">
                                                                            <img *ngIf="row.session_with_image != null && row.session_with_image != 'null'" class="rounded-circle" src="{{row.session_with_image}}" alt="image" style="width: 40px; height: 40px;" />
                                                                            <img *ngIf="row.session_with_image==null || row.session_with_image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 40px; height: 40px;" />
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="current_ffd == 'Sessions' && getsessions == null ">
                                                        <h3>No Sessions Available</h3>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template class="modal text-left" #baselineintakeForm let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header row pb-0">
                            <div class="col-7">
                                <h2  style="margin-left: 45px;" id="myModalLabel23">Baseline Intake Form</h2>
                            </div>
                            <div class="col-4" style="text-align: end;">
                                <p style="margin-top: 33px !important;margin-bottom: 0px !important;">Submitted on : {{this.submitTime | datetime:'DD-MMM-YY HH:MM:SS'}}</p>
                            </div>
                            <div class="col-1">
                                <button style="background-color: white; border: 0px;cursor: pointer; outline: none;" aria-label="Close" 
                                        (click)="d('Cross click');emptyFile();formStatusChange()">
                                    <span style="font-size: 35px;" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div class="modal-body mr-3 ml-3">
                            <form class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                <div *ngFor="let key of this.questionAnsAnswer;let i = index" class="form-group position-relative  border-bottom ">
                                    <div  class="form-group position-relative mb-0">
                                        <label class="text-style">{{i + 1}}.{{ key.question }}</label> 
                                    </div>
                                     <span *ngFor="let ans of key.answer">
                                        <label > {{ans }}</label><br>
                                    </span>
                                </div>
                                <br>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </section>
        </div>
    </div>
</div>