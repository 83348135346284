<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
  <p style="color: white">Loading... </p>
</ngx-spinner>

<div class="app-content content">
  <div class="content-wrapper p-1">

    <div class="content-body">
      <section id="configuration">
        <div class="card rounded-3">
          <div class="action-container d-flex flex-wrap align-items-center justify-content-end mb-0 pl-0 p-1">
              <!-- Add Organization -->
              <div class="action-item mr-2 mb-2 mb-md-0">
                <button type="button" class="btn btn-sm text-wrap fs-6 width-full  rounded-3 color-custom" (click)="openCreateForm(Add)">
                    <i class="la la-cloud-download"></i> Add Banner
                </button>
            </div>
              <!-- <div class="action-item mb-2 mb-md-0">
                  <button type="button" style="height: 35px;font-size: 12px;padding: 10px;"
                  class="btn btn-outline-dark " (click)="openCreateForm(Add)">Add Banner</button>
              </div> -->
              
          </div>
      </div>
        <!-- <div class="container p-0 col-12">
          <div class="card py-1 px-2">
              <ul class="d-flex flex-wrap align-items-center mb-0 pl-0" style="list-style: none;">
                  <li class="mr-1">
                      <button   (click)="openCreateForm(Add)" type="button" class="btn btn-outline-dark btn-sm">
                        <i class="la la-plus"></i> Add Banner
                      </button>
                  </li>
              </ul>
          </div>
      </div> -->
      <div class="container custom-container p-0 col-12">
        <table class="table table-striped  table-font table-font-size-14" style="font-size: 14px;">
            <thead>
                <tr >
                    <!-- Your table headers -->
                    <th style="padding-left: 5px !important; padding-right: 2px !important" class="id-width">Id</th>
                    <th style="padding-left: 5px !important; padding-right: 2px !important" class="image-width">Image</th>
                    <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Title</th>
                    <th style="padding-left: 5px !important; padding-right: 2px !important" class="padding-l wid-client-mob-orgina">Contect</th>
                    <th style="padding-left: 5px !important; padding-right: 2px !important">Action</th>
                </tr>
            </thead>
            <tbody *ngIf=(this.banners)>
                <tr class="text-align: center;" *ngFor="let row of banners; let i = index">
                    <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class=" image-width">
                        <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                        <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                    </td>
                    <td class="status-log-email">  {{row.title | titlecase}}</td>
                    <td  class="padding-l wid-client-mob-orgina">  {{row.content | titlecase}}</td>
                    <td>
                      <i (click)="editBanner(row.id,Edit)" class="la la-pencil-square-o text-success hoverclass"
                      aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                    <i (click)="deleteOrg(row.id)" class="la la-trash text-danger hoverclass"
                      aria-hidden="true"></i>
                    </td>
                </tr>
            </tbody>
            <!-- <tbody *ngIf="this.showTable == true" >
                <tr class="text-align: center;" *ngFor="let row of this.banners ">
                    <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                    <td class=" image-width">
                        <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="Image">
                        <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                    </td>
                    <td class="status-log-email">  {{row.title | titlecase}}</td>
                    <td  class="padding-l wid-client-mob-orgina">  {{row.content | titlecase}}</td>
                    <td>
                      <i (click)="editBanner(row.id,Edit)" class="la la-pencil-square-o text-success hoverclass"
                      aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                    <i (click)="deleteOrg(row.id)" class="la la-trash text-danger hoverclass"
                      aria-hidden="true"></i>
                    </td>
                </tr>
            </tbody> -->
        </table>
        <!-- Pagination -->
         <div  class="row " style="background: #727e8e;color: #ededed; margin: 0px !important;">
            <div class="col-6">
                <h6 class="total-entries text-style" style="color: #ededed">Total Entries: {{ totalItems }}</h6>
            </div>
            <div *ngIf="this.showTable == true" class="col-6" style="justify-content: end;display: grid;">
                <ngb-pagination
                [(page)]="currentPage"
                [pageSize]="itemsPerPage"
                [collectionSize]="totalItems"
                (pageChange)="onPageChange($event)"
                aria-label="Default pagination"
                class="custom-pagination"
              ></ngb-pagination>
            </div>
        </div>
    </div>
      <!-- <div class="container custom-container p-0 col-12">
        <div class="row ">
          <div class="col-12" *blockUI="'zeroConfiguration'; message: 'Loading'">
            <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
              <ng-container mCardBody>
                <div class="card-dashboard">
                  <div style="overflow-x: scroll !important; scroll-behavior: smooth !important; width: 100%; ">
                  <ngx-datatable class="bootstrap table-bordered width-fit-content" [limit]="8" [rows]="banners" [columnMode]="'force'"  [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" >

                    <ngx-datatable-column name="Id" [sortable]="false" [flexGrow]="1" [minWidth]="50"  [maxWidth]="70">
                      <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
                        {{rowIndex + 1}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Image" [sortable]="false" [flexGrow]="1" [minWidth]="70" [maxWidth]="90">
                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                        <img *ngIf="row.image!=null && row.image != 'null' " class="rounded-circle" src="{{row.image}}"
                          alt="image" style="width: 30px; height: 30px;" />
                        <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle"
                          src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Title" [flexGrow]="1" [minWidth]="150">
                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                        {{row.title | titlecase}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Content" [draggable]="true" [sortable]="true" [minWidth]="150" [flexGrow]="1" prop="content" [flexGrow]="1" >
                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                        {{row.content | titlecase}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action" [sortable]="false" [flexGrow]="1" [minWidth]="150">
                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                        <i (click)="editBanner(row.id,Edit)" class="la la-pencil-square-o text-success hoverclass"
                          aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                        <i (click)="deleteOrg(row.id)" class="la la-trash text-danger hoverclass"
                          aria-hidden="true"></i>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                  </div>
                </div>
              </ng-container>
            </m-card>
          </div>
        </div>
      </div> -->
      </section>

      
      <section id="form-components">
        <!--Modal-->
        <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
          <div class="modal-lg">
            <div class="modal-header">
              <h6 id="myModalLabel23">Add Banner</h6>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click');formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="bannerInfo" (ngSubmit)="onSubmit()" class="m-2">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="projectinput1">Title *</label>
                        <input type="text" id="projectinput1" class="form-control" formControlName="title"
                          placeholder="Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" maxlength="100"/>
                        <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors"
                          class="invalid-feedback">
                          <div *ngIf=" f.title.errors.required">
                            Title is required</div>
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="projectinput1">Content *</label>
                        <input type="text" id="projectinput1" class="form-control" formControlName="content"
                          placeholder="Content" [ngClass]="{ 'is-invalid': submitted && f.content.errors }" maxlength="200" />
                        <small class="form-text text-muted danger" *ngIf="submitted && f.content.errors"
                          class="invalid-feedback">
                          <div *ngIf=" f.content.errors.required">
                            Content is required</div>
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label>Upload Image</label>
                      <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" formControlName="image"
                          [ngClass]="{ 'is-invalid': submitted && f.image.errors }" style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>
                      <div class="col-4" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                        <img [src]="imageURL" alt="client" class="img-fluid mb-2 rounded">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');formStatusChange()">Close</button>
                  <button type="submit" class="btn btn-outline-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
        <!-- Modal -->
        <ng-template class="modal text-left" #Edit let-c="close" let-d="dismiss">
          <div class="modal-lg">
            <div class="modal-header">
              <h6 id="myModalLabel23">Edit Banner</h6>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #edit_banner="ngForm" (ngSubmit)="updateBanner(edit_banner.value,banner.id)" class="m-2">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="projectinput1">Title *</label>
                        <input type="text" id="projectinput1" class="form-control" placeholder="Title"
                          [(ngModel)]="banner.title" name="title" maxlength="100" />

                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="projectinput1">Content *</label>
                        <input type="text" id="projectinput2" class="form-control" placeholder="Content"
                          [(ngModel)]="banner.content" name="content" maxlength="200" />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <label>Upload Image</label>
                      <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>
                      <div class="col-6" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                        <img [src]="imageURL" alt="banner" class="img-fluid mb-2 rounded">
                      </div>

                    </div>
                  </div>


                </div>

                <div class="modal-footer">
                  <button type="button" class="btn grey btn-outline-secondary"
                    (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                  <button type="submit" class="btn btn-outline-primary">Submit</button>
                </div>


              </form>
            </div>

          </div>
        </ng-template>
      </section>
    </div>
  </div>
</div>