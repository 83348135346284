<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous">
<script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
<div class="app-content content">
    <div class="content-wrapper p-1">

        <div class="content-body">
            <section id="configuration">
                <div class="card rounded-3">
                    <div class="action-container d-flex flex-wrap align-items-center justify-content-end mb-0 pl-0 p-1">
                        <!-- Add Organization -->
                        <div class="action-item mr-2 mb-2 mb-md-0">
                            <button type="button" class="btn btn-sm text-wrap fs-6 width-full  rounded-3 color-custom"(click)="openFormModal(Add)">
                                <i class="la la-user-plus" style="margin-right: 3px;"></i> Add Organization
                            </button>
                        </div>
                        <!-- <div class="action-item mr-2 mb-2 mb-md-0">
                            <button type="button" style="height: 35px;font-size: 12px;padding: 10px;"
                            class="btn btn-outline-dark " (click)="openFormModal(Add)">Add Organization</button>
                        </div> -->
                        <!-- Search Input -->
                        <div class="action-item mb-2 mb-md-0">
                            <div class="search-container">
                                <input type="text" style="border: #12343b;" class="form-control lh-base fs-6  rounded-3 shadow-lg " placeholder="Search......"  (keyup)='updatedefaultFilter($event)'>
                                <i class="la la-search search-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container p-0 col-12">
                    <div class="card py-1 px-2">
                        <ul class="d-flex flex-wrap align-items-center mb-0 pl-0" style="list-style: none;">
                            <li class="mr-1">
                                <input type='text'
                                    style='height: 35px; width:200px;border-radius: .22rem; border: 1px solid #BABFC7;'
                                    placeholder='Search' (keyup)='updatedefaultFilter($event)'
                                    class="search-input-small search-width" />
                            </li>
                            <li class="">
                                <button type="button" style="height: 35px;font-size: 12px;padding: 10px;"
                                    class="btn btn-outline-dark " (click)="openFormModal(Add)">Add Organization</button>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="container custom-container p-0 col-12">
                    <table class="table table-striped table-font table-font-size-14" style="font-size: 14px;">
                        <thead>
                            <tr >
                                <!-- Your table headers -->
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="id-width">S.No</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="image-width">Id</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Image</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="padding-l wid-client-mob-orgina">Organization Name</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Status</th>
                                <th style="padding-left: 5px !important; padding-right: 2px !important">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf=(this.orgs)>
                            <tr class="text-align: center;" *ngFor="let row of this.orgs; let i = index">
                                <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                <td class="status-log-email">{{ row.id }} </td>
                                <td class=" image-width">
                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                                </td>
                                <td class="status-log-email">{{ row.name }} </td>
                                <td class="status-log-email">  
                                    <select [value]="row.status" (change)="updateOrganizationStatus(row.id,$event)" [disabled]="row.id == motus_org_id">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </td>
                                    <td>
                                        <div class="m-0 d-flex justify-items-center gap-3">
                                            <i (click)="editOrg(row.id,Edit)" *ngIf="row.id != motus_org_id " class="la la-pencil-square-o text-success hoverclass " aria-hidden="true"></i>
                                            <i *ngIf="row.id == motus_org_id " class="la la-pencil-square-o text-light " aria-hidden="true"></i>
                                            <i class="la la-trash text-light " aria-hidden="true" *ngIf="row.id == motus_org_id "></i>
                                            <i (click)="deleteOrg(row.id)" class="la la-trash text-danger hoverclass " aria-hidden="true" *ngIf="row.id != motus_org_id "></i>
                                            <i (click)="redirectOrg(row.id)" class="la la-users  hoverclass " aria-hidden="true"></i>
                                            <img src="assets/images/FFD.png" alt="image" style="width: 30px; height:15px;" (click)="redirectFfd(row.id);openFfdModal(OrgFfd)" class="hoverclass" />
                                            <button type="button" class="btn btn-outline-secondary btn-sm ml-2" (click)="openCreateClientFormModal(AddClient);this.client_details.get('org_id').setValue(row.id)" *ngIf="row.id != motus_org_id ">
                                                <i class="la la-plus"></i>
                                                <i class="la la-user"></i>
                                            </button>
                                        </div>
                                    </td>
                               
                            </tr>
                        </tbody>
                        <tbody *ngIf="this.showTable == true" >
                            <tr class="text-align: center;" *ngFor="let row of this.orgs | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index">
                                <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                <td class="status-log-email">{{ row.id }}</td>
                                <td class=" image-width">
                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                                </td>
                                <td class="status-log-email"> {{row.name}}</td>
                                <td>
                                    <select [value]="row.status" (change)="updateOrganizationStatus(row.id,$event)" [disabled]="row.id == motus_org_id">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </td>
                                <td>
                                    <div class="m-0 d-flex justify-items-center gap-3">
                                        <i (click)="editOrg(row.id,Edit)" *ngIf="row.id != motus_org_id " class="la la-pencil-square-o text-success hoverclass " aria-hidden="true"></i>
                                        <i *ngIf="row.id == motus_org_id " class="la la-pencil-square-o text-light " aria-hidden="true"></i>
                                        <i class="la la-trash text-light " aria-hidden="true" *ngIf="row.id == motus_org_id "></i>
                                        <i (click)="deleteOrg(row.id)" class="la la-trash text-danger hoverclass " aria-hidden="true" *ngIf="row.id != motus_org_id "></i>
                                        <i (click)="redirectOrg(row.id)" class="la la-users  hoverclass " aria-hidden="true"></i>
                                        <img src="assets/images/FFD.png" alt="image" style="width: 30px; height:15px;" (click)="redirectFfd(row.id);openFfdModal(OrgFfd)" class="hoverclass" />
                                        <button type="button" class="btn btn-outline-secondary btn-sm ml-2" (click)="openCreateClientFormModal(AddClient);this.client_details.get('org_id').setValue(row.id)" *ngIf="row.id != motus_org_id ">
                                            <i class="la la-plus"></i>
                                            <i class="la la-user"></i>
                                        </button>
                                    </div>
                                </td>
                               
                            </tr>
                        </tbody>
                    </table>
                    <!-- Pagination -->
                     <div  class="row" style="background: #727e8e;color: #ededed; margin: 0px !important;">
                        <div class="col-6">
                            <h6 class="total-entries text-style" style="color: #ededed">Total Entries: {{ totalItems }}</h6>
                        </div>
                        <div *ngIf="this.showTable == true" class="col-6" style="justify-content: end;display: grid;">
                            <ngb-pagination
                            [(page)]="currentPage"
                            [pageSize]="itemsPerPage"
                            [collectionSize]="totalItems"
                            (pageChange)="onPageChange($event)"
                            aria-label="Default pagination"
                            class="custom-pagination"
                          ></ngb-pagination>
                        </div>
                    </div>
                  </div>
                <!-- <div class="container custom-container p-0 col-12">
                    <div class="row">
                        <div class="col-12" *blockUI="'zeroConfiguration'; message: 'Loading'">
                            <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
                              

                                <ng-container mCardBody>
                                    <div class="card-dashboard">
                                        <div
                                            style="overflow-x: scroll !important; scroll-behavior: smooth !important; width: 100%;">
                                            <ngx-datatable class="bootstrap table-bordered  width-fit-content"
                                                 [rows]="orgs" [columnMode]="'force'" [headerHeight]="50"
                                                [footerHeight]="50" [rowHeight]="50">

                                                <ngx-datatable-column name="S.No." [sortable]="false" [flexGrow]="1"
                                                    [minWidth]="50" [maxWidth]="90">
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                        let-rowIndex="rowIndex" let-row="row">
                                                        {{rowIndex + 1}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Id" [sortable]="false" [flexGrow]="1"
                                                    [minWidth]="50" [maxWidth]="90">
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                        let-row="row">
                                                        {{row.id}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Image" [sortable]="false" [flexGrow]="1"
                                                    [minWidth]="70" [maxWidth]="100">
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                        let-row="row">
                                                        <img *ngIf="row.image != null && row.image != 'null' "
                                                            class="rounded-circle" src="{{row.image}}" alt="image"
                                                            style="width: 30px; height: 30px;" />
                                                        <img *ngIf="row.image==null || row.image=='null' "
                                                            class="rounded-circle" src="assets/images/no-image.png"
                                                            alt="image" style="width: 30px; height: 30px;" />
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Organization Name" [draggable]="true"
                                                    [sortable]="true" [flexGrow]="1" prop="name" [flexGrow]="1"
                                                    [minWidth]="200" [maxWidth]="250">
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                        let-row="row">
                                                        {{row.name}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Status" [sortable]="false" [flexGrow]="1"
                                                    [minWidth]="100" [maxWidth]="250">
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                        let-row="row">
                                                        <select [value]="row.status"
                                                            (change)="updateOrganizationStatus(row.id,$event)"
                                                            [disabled]="row.id == motus_org_id">
                                                            <option value="active">Active</option>
                                                            <option value="inactive">Inactive</option>
                                                        </select>

                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Action" [sortable]="false" [flexGrow]="1"
                                                    [minWidth]="300">
                                                    <ng-template ngx-datatable-cell-template let-value="value"
                                                        let-row="row">
                                                        <i (click)="editOrg(row.id,Edit)"
                                                            *ngIf="row.id != motus_org_id "
                                                            class="la la-pencil-square-o text-success hoverclass mx-1"
                                                            aria-hidden="true"></i>
                                                        <i *ngIf="row.id == motus_org_id "
                                                            class="la la-pencil-square-o text-light mx-1"
                                                            aria-hidden="true"></i>
                                                        <i class="la la-trash text-light mx-1" aria-hidden="true"
                                                            *ngIf="row.id == motus_org_id "></i>
                                                        <i (click)="deleteOrg(row.id)"
                                                            class="la la-trash text-danger hoverclass mx-1"
                                                            aria-hidden="true" *ngIf="row.id != motus_org_id "></i>
                                                        <i (click)="redirectOrg(row.id)"
                                                            class="la la-users  hoverclass mx-1" aria-hidden="true"></i>
                                                        <img src="assets/images/FFD.png" alt="image"
                                                            style="width: 30px; height:15px;"
                                                            (click)="redirectFfd(row.id);openFfdModal(OrgFfd)"
                                                            class="hoverclass" />
                                                        <button type="button"
                                                            class="btn btn-outline-secondary btn-sm ml-2"
                                                            (click)="openCreateClientFormModal(AddClient);this.client_details.get('org_id').setValue(row.id)"
                                                            *ngIf="row.id != motus_org_id ">
                                                            <i class="la la-plus"></i>
                                                            <i class="la la-user"></i>
                                                        </button>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </div>
                                    </div>
                                </ng-container>
                            </m-card>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="container custom-container p-0 col-12">
                    <div class="row">
                        <div class="col-12" *blockUI="'zeroConfiguration'; message: 'Loading'">
                            <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
                                <ng-container mCardBody>
                                    <div class="card-dashboard">
                                       
                                        <div style="overflow-x: scroll !important; scroll-behavior: smooth !important; width: 100%;">
                                        <ngx-datatable class="bootstrap table-bordered  width-fit-content" [limit]="8" [rows]="orgs" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" >

                                            <ngx-datatable-column name="S.No." [sortable]="false" [flexGrow]="1" [minWidth]="50" [maxWidth]="90">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
                                                    {{rowIndex + 1}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Id" [sortable]="false" [flexGrow]="1" [minWidth]="50" [maxWidth]="90">
                                                <ng-template ngx-datatable-cell-template let-value="value"  let-row="row">
                                                    {{row.id}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Image" [sortable]="false" [flexGrow]="1" [minWidth]="70" [maxWidth]="100">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    <img *ngIf="row.image != null && row.image != 'null' " class="rounded-circle" src="{{row.image}}" alt="image" style="width: 30px; height: 30px;" />
                                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Organization Name" [draggable]="true" [sortable]="true" [flexGrow]="1" prop="name" [flexGrow]="1" [minWidth]="200" [maxWidth]="250">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    {{row.name}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Status" [sortable]="false" [flexGrow]="1" [minWidth]="100" [maxWidth]="250">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    

                                                    <select [value]="row.status" (change)="updateOrganizationStatus(row.id,$event)" [disabled]="row.id == motus_org_id">
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            </select>

                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Action" [sortable]="false" [flexGrow]="1" [minWidth]="300">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    <i (click)="editOrg(row.id,Edit)" *ngIf="row.id != motus_org_id " class="la la-pencil-square-o text-success hoverclass mx-1" aria-hidden="true"></i>
                                                    <i *ngIf="row.id == motus_org_id " class="la la-pencil-square-o text-light mx-1" aria-hidden="true"></i>
                                                    <i class="la la-trash text-light mx-1" aria-hidden="true" *ngIf="row.id == motus_org_id "></i>
                                                    <i (click)="deleteOrg(row.id)" class="la la-trash text-danger hoverclass mx-1" aria-hidden="true" *ngIf="row.id != motus_org_id "></i>
                                                    <i (click)="redirectOrg(row.id)" class="la la-users  hoverclass mx-1" aria-hidden="true"></i>
                                                    <img src="assets/images/FFD.png" alt="image" style="width: 30px; height:15px;" (click)="redirectFfd(row.id);openFfdModal(OrgFfd)" class="hoverclass" />
                                                    <button type="button" class="btn btn-outline-secondary btn-sm ml-2" (click)="openCreateClientFormModal(AddClient);this.client_details.get('org_id').setValue(row.id)" *ngIf="row.id != motus_org_id ">
                            <i class="la la-plus"></i>
                            <i class="la la-user"></i>
                            </button>
                                                </ng-template>
                                            </ngx-datatable-column>
                                           
                                        </ngx-datatable>
                                        </div>
                                    </div>
                                </ng-container>
                            </m-card>
                        </div>
                    </div>
                </div> -->
            </section>
            <section id="form-components">
                <!-- Modal add org-->
                <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Add Organization</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyImage();formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="orgInfo" (ngSubmit)="onSubmit()" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput1">Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="name" placeholder="Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf=" f.name.errors.required">
                            Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" 
                          [ngClass]="{ 'is-invalid': submitted && f.image.errors }" style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>

                                            <div class="col-4" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                                                <img [src]="imageURL" alt="Organization" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyImage();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>

                <!-- Modal edit org-->
                <ng-template class="modal text-left" #Edit let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Edit Organization</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyImage();formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <form #edit_coach_form="ngForm" (ngSubmit)="updateOrganization(org,org.id)" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput1">Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" placeholder=" Name" [(ngModel)]="org.name" name="name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.name.errors"
                          class="invalid-feedback">
                          <div *ngIf=" f.name.errors.required">
                             Name is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group ">
                                                <label for="projectinput5">Status*</label>
                                                <select id="projectinput5" class="form-control" name="status" [(ngModel)]="org.status" [disabled]="org.id == motus_org_id">
                          <option disabled>--Select--</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.status.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.status.errors.required">
                            Status is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" name="image" style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>

                                            <div class="col-6" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                                                <img [src]="imageURL" alt="Organization" class="img-fluid mb-2 rounded">
                                            </div>


                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                        <div *ngIf="f.image.errors.required">Image
                          is required</div>
                      </small> -->
                                        </div>




                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyImage();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </ng-template>

                <!-- Modal add client-->
                <ng-template class="modal text-left" #AddClient let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Add Client</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyImage();formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="client_details" (ngSubmit)="createClient()" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && client_control.first_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.first_name.errors" class="invalid-feedback">
                          <div *ngIf=" client_control.first_name.errors.required">
                            First Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" formControlName="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && client_control.last_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.last_name.errors" class="invalid-feedback">
                          <div *ngIf="client_control.last_name.errors.required">
                            Last Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput3">E-mail *</label>
                                                <input type="text" id="projectinput3" class="form-control" formControlName="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && client_control.email.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.email.errors" class="invalid-feedback">
                          <div *ngIf="client_control.email.errors.required">Email
                            is required</div>
                          <div *ngIf="client_control.email.errors.email">Email must
                            be a valid email address</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" formControlName="mobile" placeholder="Mobile No." [ngClass]="{ 'is-invalid': submitted && client_control.mobile.errors }" (keypress)="keyPress($event)" maxlength="10" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.mobile.errors" class="invalid-feedback">
                          <div *ngIf="client_control.mobile.errors.required">Mobile No.
                            is required</div>
                        </small>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Client Type*</label>
                                                <select id="projectinput5" class="form-control" formControlName="client_type" [ngClass]="{ 'is-invalid': submitted && client_control.client_type.errors }">
                          <option disabled>--Select--</option>
                          <option value="member">Member</option>
                          <option value="owner" selected>Owner</option>
                        </select>
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.client_type.errors" class="invalid-feedback">
                          <div *ngIf="client_control.client_type.errors.required">
                            Client type is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput6">Organization*</label>
                                                <select id="projectinput6" class="form-control" formControlName="org_id" [ngClass]="{ 'is-invalid': submitted && client_control.org_id.errors }">
                          <option disabled>--Select--</option>
                          <option *ngFor="let o of orgs_for_clients" [value]="o.id">{{o.name}}</option>
                        </select>
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.org_id.errors" class="invalid-feedback">
                          <div *ngIf="client_control.org_id.errors.required">
                            Organization is required</div>
                        </small>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" id="title" class="form-control" formControlName="title" placeholder="Title" [ngClass]="{ 'is-invalid': submitted && client_control.title.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.title.errors" class="invalid-feedback">
                          <div *ngIf="client_control.title.errors.required">Title
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="division">Department</label>
                                                <input type="text" id="division" class="form-control" formControlName="division" placeholder="Department" [ngClass]="{ 'is-invalid': submitted && client_control.division.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.division.errors" class="invalid-feedback">
                          <div *ngIf="client_control.division.errors.required">Department
                            is required</div>

                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="reportees">Reportees</label>
                                                <input type="text" id="reportees" class="form-control" formControlName="reportees" placeholder="Reportees" [ngClass]="{ 'is-invalid': submitted && client_control.reportees.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.reportees.errors" class="invalid-feedback">
                          <div *ngIf="client_control.reportees.errors.required">Reportees
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="dob">Date of birth</label>
                                                <input type="date" id="dob" class="form-control" formControlName="dob" placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && client_control.dob.errors }" [max]="eighteen_y_before" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.dob.errors" class="invalid-feedback">
                          <div *ngIf="client_control.dob.errors.required">Date of birth
                            is required</div>

                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="work_anniversary">Work anniversary</label>
                                                <input type="date" id="work_anniversary" class="form-control" formControlName="work_anniversary" placeholder="Work anniversary" [ngClass]="{ 'is-invalid': submitted && client_control.work_anniversary.errors }" [min]="today_date" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && client_control.work_anniversary.errors" class="invalid-feedback">
                          <div *ngIf="client_control.work_anniversary.errors.required">Work anniversary
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput10" class="file center-block">
                        <input type="file" id="file" 
                           style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" [alt]="client_details.value.name" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyImage();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>

                <!-- ffd -->
                <ng-template class="modal text-left" #OrgFfd let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">FFD</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');clearFfd()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <div class="row justify-content-center">
                                <div class="col-md-6">
                                    <div class="card">
                                        <h4 class="card-title m-1">
                                            <div class="badge badge-pill" style="background-color: #CF4144;">{{total_sessions}}</div>&nbsp; Session
                                        </h4>

                                        <div class="text-center">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card" style="border-radius: 20px; background-color:#a2a79d" (click)="current_ffd = 'Elephants'; current_ffd_data = elephants">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/Elephant.png" alt="image" style="width: 20px; height: 20px;" />
                                                                            <h4 style="color: #FFFFFF;">Elephants </h4>
                                                                            <h6 style="color: #FFFFFF;">{{eCounts.is_done}}/{{eCounts.total}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="eCounts.percent" [radius]="15" [outerStrokeWidth]="1" [innerStrokeWidth]="0" [outerStrokeColor]="'#ffffff'"
                                                                                    [innerStrokeColor]="'#a2a79d'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card" style="border-radius: 20px; background-color: #ce4244;" (click)="current_ffd = 'Blindspots'; current_ffd_data = blindspots">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/ActionItem.png" alt="image" style="width: 20px; height: 20px;" />
                                                                            <h4 style="color: #FFFFFF;">Blindspots </h4>
                                                                            <h6 style="color: #FFFFFF;">{{blindspotCount.is_done}}/{{blindspotCount.total}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="blindspotCount.percent" [radius]="15" [outerStrokeWidth]="1" [innerStrokeWidth]="1"
                                                                                    [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#ce4244'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card" style="border-radius: 20px; background-color: #3d4232" (click)="current_ffd = 'Action Items'; current_ffd_data = action_items">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/ActionItem.png" alt="image" style="width: 20px; height: 20px;" />
                                                                            <h4 style="color: #FFFFFF;">Action Items</h4>
                                                                            <h6 style="color: #FFFFFF;">{{actionItemCount.is_done}}/{{actionItemCount.total}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="actionItemCount.percent" [radius]="15" [outerStrokeWidth]="1" [innerStrokeWidth]="1"
                                                                                    [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#3d4232'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="card" style="border-radius: 20px; background-color: #f3efef" (click)="current_ffd = 'Takeaways'; current_ffd_data = take_aways">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <img src="assets/images/TakeAway.png" alt="image" style="width: 20px; height: 20px;" />
                                                                            <h4 style="color: #CF4144;">Takeaways </h4>
                                                                            <h6 style="color: #CF4144;">{{takeAwayCount.total}}</h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="card pull-up hoverclass" style="border-radius: 20px; background-color: #ce4244;" (click)="current_ffd = 'Sessions'">
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="media d-flex">
                                                                        <div class="media-body text-left">
                                                                            <i class="feather ft-eye h4" style="color: #FFFFFF;"></i>
                                                                            <h4 style="color: #FFFFFF;">Sessions</h4>
                                                                            <h6 style="color: #FFFFFF;">
                                                                                {{completed}}/{{total_sessions}} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <circle-progress [percent]="percent_sess" [radius]="10" [outerStrokeWidth]="1" [innerStrokeWidth]="1" [outerStrokeColor]="'#ffffff'" [innerStrokeColor]="'#ce4244'" [animation]="true" [animationDuration]="100"></circle-progress>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card ">
                                        <div class="" *ngIf="( current_ffd == 'Sessions')">
                                            <select name="client_filter" id="client_filter" class="form-control form-control-sm" (change)="filterSessions($event)" value="'0'">
                        <option value="0">All</option>
                        <option *ngFor="let client of current_org_clients"  [value]="client.id">{{client.first_name}} {{client.last_name}}</option>
                      </select>
                                        </div>
                                    </div>
                                    <div class="card" *ngIf="( current_ffd == 'Sessions')">
                                        <div class="text-center m-0">

                                            <div class="card-body m-0">
                                                <div id="new-orders" class="media-list position-relative m-0 tbodyDiv">
                                                    <div class="table-responsive" *ngIf="org_sessions.length > 0" style="margin-top: -10px;">
                                                        <table id="recent-orders" class="table  mb-0">
                                                            <tbody>
                                                                <tr *ngFor="let row of org_sessions" >
                                                                    <td class="p-1">

                                                                        <div class=" text-bold-800 " style="border-radius: 10px; background-color: #ce4244; padding: 5px; color: white; font-size: 27px;padding-top: 5px;" [class.completed-session]="row.status == 'completed' ">
                                                                            {{(row.session_date + ' ' + row.session_time) | datetime:'DD'}}<br> {{ (row.session_date + ' ' + row.session_time) | datetime:'MMM'}}</div>
                                                                    </td>
                                                                    <td class="text-start " style="padding-left: 5px;">
                                                                        <h6 style="margin-bottom: 0px; margin-top: 2px;"> {{row.session_with |titlecase}}</h6>
                                                                        <h6 style="margin-bottom: 0px;margin-top: 5px;"> {{row.title |titlecase}}</h6>
                                                                        <h6 style="margin-bottom: 0px;margin-top: 5px;"> {{ (row.session_date + ' ' + row.session_time) |datetime|uppercase}}
                                                                        </h6>
                                                                    </td>
                                                                    <td class="p-1">

                                                                        <div class="avatar avatar-md mt-2">
                                                                            <img *ngIf="row.session_with_image != null && row.session_with_image != 'null'" class="rounded-circle" src="{{row.session_with_image}}" alt="image" style="width: 40px; height: 40px;" />
                                                                            <img *ngIf="row.session_with_image==null || row.session_with_image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 40px; height: 40px;" />
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="current_ffd == 'Sessions' && (org_sessions.length <= 0)">
                                                        <h3>No Sessions Available</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </section>
        </div>
    </div>
</div>