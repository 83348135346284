import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstUrl } from 'src/app/_constant';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {
  banners: any = [];
  imageURL: string;
  file: any;
  banner: any;
  submitted: boolean = false;
  bannerInfo: FormGroup;
  image: any;
  totalItems:any;
  pageSize = 20; // You can adjust the page size
  currentPage = 1;
  itemsPerPage = 20;
  totalRows = 0;  // Total number of items, set it based on your data
  showTable: boolean;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

  ) { }
  ngOnInit() {
    this.bannerInfo = this.formBuilder.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      image: [''],
    })
    this.getBanner();
  }
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollX: true };

  get f() {
    return this.bannerInfo.controls;
  }
  formStatusChange(status:boolean = false){
    this.submitted = status;
  }
  emptyFile() {
    this.file = '';
    this.imageURL = null;
  }
  onFileSelected(event) {
    if(!((event.target as HTMLInputElement).files[0].size <= ConstUrl.max_image_size)){
      this.toastr.error('Maximum allowed size is 5MB')
      return 
    }
    this.file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.file)
  }
  openCreateForm(LoginFormContent) {
    this.modalService.open(LoginFormContent, { windowClass: 'animated FadeIn', backdrop: 'static', size: 'md' });
  }
  getBanner() {
    this.spinner.show();
    this.ApiService.getBanner().subscribe((response: any) => {
      if (response.data.success) {
        this.banners = response.data.data;
        this.totalItems = this.banners.length;
      } else {
        this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    })
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    if (this.bannerInfo.invalid) {
      this.spinner.hide();
      return;
    }
    let formData = new FormData();
    Object.keys(this.bannerInfo.value).forEach((itm) => {
      formData.append(itm, this.bannerInfo.value[itm]);
    })
    if (this.file) {
      formData.append('image', this.file)
    }
    this.ApiService.createBanner(formData).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.bannerInfo.reset()
        this.image = null
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }
  editBanner(id: any, modalName: any) {
    let row = this.banners.find((c: any) => { return c.id == id });
    this.banner = { ...row };
    this.imageURL = this.banner.image;
    this.openCreateForm(modalName);
  }
  deleteOrg(id: any) {
    let is_confirmed = confirm("Are you sure to delete this record?");
    if(is_confirmed){
      this.spinner.show();
      this.ApiService.deleteBanner(id).subscribe(response => {
        if (response.data.success) {
          this.toastr.success(response.data.message);
          this.ngOnInit();
        } else {
          this.toastr.error(response.data.message);
        }
        this.spinner.hide();
      })
    }
  }
  updateBanner(data: any, id: any) {
    this.spinner.show();

    let formData = new FormData();
    formData.append('title', data.title);
    formData.append('content', data.content);

    if (this.file) {
      formData.append('image', this.file)
    } else {
      delete data.image;
    }
    this.ApiService.updateBanner(formData, id).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.banner = {};
        this.file = ''
        this.imageURL = null;

        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        // this.toastr.error(response.message);
        var error_msg: string;
        if (response.data) {
          error_msg = Object.values(response.data)[0] as string;
        } else {
          error_msg = response.message;
        }
        this.toastr.error(error_msg);

      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }


}
