// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCSG-EGU2RLITgpjf_ogf1w47edC_DXlWc',
  authDomain: 'evolvision-rnd.firebaseapp.com',
  databaseURL: 'https://evolvision-rnd.firebaseio.com',
  projectId: 'evolvision-rnd',
  storageBucket: 'evolvision-rnd.appspot.com',
  messagingSenderId: '890895206035',
  appId: '1:890895206035:web:a6d0e1574d59ffe40bef66',
  measurementId: 'G-WYKKLCYFHE'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',

  admin_server: 'https://app.motus9.com/motus_prod_api/',
  SOCKET_ENDPOINT: 'https://app.motus9.com/',
  SOCKET_NAMESPACE: '/motus_prod.io/',
  S3_URL: 'https://' + "motus9" + '.s3.' + "us-east-1" + '.amazonaws.com/',
  // production: false,
  // firebase: {
  //   apiKey: 'AIzaSyCSG-EGU2RLITgpjf_ogf1w47edC_DXlWc',
  //   authDomain: 'evolvision-rnd.firebaseapp.com',
  //   databaseURL: 'https://evolvision-rnd.firebaseio.com',
  //   projectId: 'evolvision-rnd',
  //   storageBucket: 'evolvision-rnd.appspot.com',
  //   messagingSenderId: '890895206035',
  //   appId: '1:890895206035:web:a6d0e1574d59ffe40bef66'
  // },
  // googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  // admin_server: 'http://localhost:5002/',
  // SOCKET_ENDPOINT: 'http://localhost:5003/',
  // SOCKET_NAMESPACE: '/socket.io/',
  // S3_URL: 'https://' + "motus9" + '.s3.' + "us-east-1" + '.amazonaws.com/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
