<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section class="row">
                <div class="d-flex justify-content-center align-items-center w-100">
                    <div class="col-lg-6 col-md-10 col-sm-12 box-shadow-2 p-0 mobile-bottom">
                        <div class="card border-grey border-lighten-3 m-0 p-5">
                            <div class="card-header border-0 container-plpr">
                                <div class="card-title text-center">
                                    <div style="font-size: 48px;">
                                        <img [src]="logo_url" style="width: 100px; height: 100px;" alt="branding logo">
                                    </div>
                                </div>
                                <h2 class="card-subtitle line-on-side text-muted text-center pt-2">
                                    <span class="font-weight-bold" style="font-size: 17px;">Baseline Intake Form</span>
                                </h2>
                            </div>
                            <div class="card-content container container-plpr">
                                <form *ngIf="this.user_type == 'member'" class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                    <div *ngFor="let row of baselineintake; let i = index" class="form-group position-relative mb-3">
                                        <label class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                            <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                                <textarea id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}"  required  class="textarea-field" maxlength="200" placeholder="Answer"></textarea>
                                                <div *ngIf="row.scale == '0'" class="container-plpr">
                                                    <div class="slider-labels text-style">
                                                        <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">
                                                            {{ stepValue }}
                                                        </span>
                                                    </div>
                                                    <input type="range" class="form-control-range example-margin custom-slider" 
                                                    [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1" >
                                                </div>
                                            </div>
                                          
                                    </div>
                                    <div class="text-center mobile-bottom">
                                      <button type="submit" class="btn btn-mtu btn-end btn-width">Submit</button>
                                    </div>
                                  </form>

                                <form *ngIf="this.user_type == 'owner'" class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                    <div *ngFor="let row of baselineintake; let i = index" class="form-group mb-3">
                                        <div *ngIf="row.question_for == 'member'">
                                            <label class="label text-style">{{ i + 1 }}. {{ row.question }}</label>
                                            <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                              <textarea id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}"  required  class="textarea-field" maxlength="200" placeholder="Answer"></textarea>
                                              <div *ngIf="row.scale == '0'" class="container-plpr">
                                                <div class="slider-labels text-style">
                                                  <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">{{ stepValue }}</span>
                                                </div>
                                                <input type="range"required class="form-control-range custom-slider" [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1">
                                              </div>
                                            </div>
                                            <!-- <label class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                            <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                                <textarea class="form-control" id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}" placeholder=""></textarea>
                                                <div *ngIf="row.scale == '0'" class="container-plpr">
                                                    <div class="slider-labels text-style">
                                                        <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">{{ stepValue }}</span>
                                                    </div>
                                                    <input type="range" class="form-control-range custom-slider" [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1">
                                                </div>
                                            </div> -->
                                        </div>

                                        <div *ngIf="row.question_for == 'owner'">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label class="text-style">{{ i + 1 }}. {{ row.question }}:</label>
                                                </div>
                                                <div class="col-md-8">
                                                    <textarea class="form-control" id="{{row.question}}" ngModel name="{{row.question}}" placeholder=""></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center mobile-bottom">
                                        <button type="submit" class="btn btn-mtu btn-center btn-width">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>


<!-- <div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body  ">
            <section class="row">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-lg-8 col-md-9 box-shadow-2 p-0 mobile_bottom">
                        <div class="card border-grey border-lighten-3 m-0 p-5 padding_remove ">
                            <div class="card-header border-0 container container-plpr">
                                <div class="card-title text-center ">
                                    <div style="font-size: 48px;">
                                        <img [src]="logo_url" style="width: 100px; height: 100px;" alt="branding logo">
                                    </div>
                                </div>
                                <h2 class="card-subtitle line-on-side text-color text-muted text-center  pt-2">
                                    <span class="font-weight-bold text-color" style="font-size: 17px;" >Baseline Intake Form </span>
                                </h2>
                            </div>
                            <div class="card-content container container-plpr">
                            <form *ngIf=" this.user_type == 'member'" class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                <div *ngFor="let row of baselineintake; let i = index" class="form-group position-relative mb-3">
                                    <label class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                    <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                        <textarea class="form-control" id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}" placeholder=""></textarea>
                                        <div *ngIf="row.scale == '0'" class="container-plpr">
                                            <div class="slider-labels text-style">
                                                <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">{{ stepValue }} </span>
                                            </div>
                                            <input type="range" class="form-control-range example-margin custom-slider" 
                                            [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1" >
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="mobile_bottom" style="text-align: center">
                                    <button type="submit" class="btn  btn-mtu btn-center btn-width">Submit</button>
                                </div>
                            </form>
                            <form *ngIf=" this.user_type == 'owner'" class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                <div *ngFor="let row of baselineintake; let i = index" class="form-group position-relative mb-3">
                                    <div *ngIf=" row.question_for == 'member'">
                                        <label  class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                        <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                            <textarea class="form-control" id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}" placeholder=""></textarea>
                                            <div *ngIf="row.scale == '0'" class="container-plpr">
                                                <div class="slider-labels text-style">
                                                    <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">
                                                        {{ stepValue }}
                                                    </span>
                                                </div>
                                                <input type="range" class="form-control-range example-margin custom-slider" 
                                                [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1" >
                                            </div>
                                        </div>
                                    </div>
                                    <div  *ngIf=" row.question_for == 'owner'">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label class="mt-1 text-style">{{ i + 1 }}. {{ row.question }}:</label>
                                            </div>
                                            <div class="col-md-8"> 
                                                <textarea type="text" class="form-control"  id="{{row.question}}" ngModel name="{{row.question}}" placeholder=""  ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="mobile_bottom" style="text-align: center">
                                    <button type="submit" class="btn  btn-mtu btn-center btn-width">Submit</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div> -->