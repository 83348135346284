<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper p-0 p-1">

        <div class="content-body p-0">
            <section id="configuration">

              <div class="card rounded-3">
                <div class="action-container d-flex flex-wrap align-items-center justify-content-end mb-0 pl-0 p-1">
   
                    <div class="action-item mr-2 mb-2 mb-md-0">
                      <label class="btn btn-sm text-wrap fs-6 width-full  rounded-3 m-0 color-custom">
                        <i class="la la-upload" style="margin-right: 3px;"></i> Upload CSV
                        <input type="file" class="custom-file-input" id="importFile" (change)="onFileSelected($event, 'csv')" accept=".csv" style="display: none;">
                      </label>
                    </div>
                    <div class="action-item mr-2 mb-2 mb-md-0">
                      <button type="button" class="btn btn-sm text-wrap color-custom fs-6 width-full  rounded-3" (click)="importCoaches()">
                          <i class="la la-sign-in" style="margin-right: 3px;"></i> Import
                      </button>
                    </div>
                    <div class="action-item mr-2 mb-2 mb-md-0  rounded-3"> 
                      <a [href]="coaches_excel_sample" class="btn  btn-sm width-full rounded-3 color-custom">
                          <i class="la la-cloud-download" style="margin-right: 3px;"></i> Sample Coaches
                      </a>
                    </div>
                   

                    <div class="action-item mr-2 mb-2 mb-md-0">
                        <button type="button" class="btn btn-sm text-wrap fs-6 width-full  rounded-3 color-custom" (click)="openCreateForm(Add)">
                            <i class="la la-user-plus" style="margin-right: 3px;"></i> Add Coach
                        </button>
                    </div>
                    <div class="action-item mb-2 mb-md-0">
                        <div class="search-container">
                            <input type="text" style="border: #12343b;" class="form-control lh-base fs-6  rounded-3 shadow-lg " placeholder="Search......" (keyup)='updatedefaultFilter($event)'>
                            <i class="la la-search search-icon"></i>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div class="container custom-container p-0 col-12">
              <!-- <div class=" m-0 p-0 bg-white">
                <div class="action-container d-flex flex-wrap align-items-center justify-content-end mb-0 pl-0 p-1">
                  
                    <div class="action-item mr-2 mb-2 mb-md-0">
                      <label class="btn btn-sm text-wrap fs-6 width-full  rounded-3 m-0 color-custom">
                        <i class="la la-upload" style="margin-right: 3px;"></i> Upload CSV
                        <input type="file" class="custom-file-input" id="importFile" (change)="onFileSelected($event, 'csv')" accept=".csv" style="display: none;">
                      </label>
                    </div>
            
                   
                    <div class="action-item mr-2 mb-2 mb-md-0">
                      <button type="button" class="btn btn-sm text-wrap color-custom fs-6 width-full  rounded-3" (click)="importCoaches()">
                          <i class="la la-sign-in" style="margin-right: 3px;"></i> Import
                      </button>
                    </div>
            
                    
                    <div class="action-item mr-2 mb-2 mb-md-0  rounded-3"> 
                      <a [href]="coaches_excel_sample" class="btn  btn-sm width-full rounded-3 color-custom">
                          <i class="la la-cloud-download" style="margin-right: 3px;"></i> Sample Coaches
                      </a>
                    </div>
                   

                    <div class="action-item mr-2 mb-2 mb-md-0">
                        <button type="button" class="btn btn-sm text-wrap fs-6 width-full  rounded-3 color-custom" (click)="openCreateForm(Add)">
                            <i class="la la-user-plus" style="margin-right: 3px;"></i> Add Coach
                        </button>
                    </div>
                   
                    <div class="action-item mb-2 mb-md-0">
                        <div class="search-container">
                            <input type="text" style="border: #12343b;" class="form-control lh-base fs-6  rounded-3 shadow-lg " placeholder="Search......" (keyup)='updatedefaultFilter($event)'>
                            <i class="la la-search search-icon"></i>
                        </div>
                    </div>
                  
                </div>
            </div> -->
              <table class="table table-hover table-font table-font-size-14" style="font-size: 14px;">
                  <thead>
                      <tr >
                          <!-- Your table headers -->
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="id-width">Id</th>
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="image-width">Image</th>
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Name</th>
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="padding-l wid-client-mob-orgina">Mobile</th>
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="email-width status-log-email  " >Email</th>
                          <!-- <th style="padding-left: 5px !important; padding-right: 2px !important" class="padding-l wid-client-mob-orgina">Client Type</th> -->
                          <!-- <th style="padding-left: 5px !important; padding-right: 2px !important" class="wid-client-mob-orgina ">Organization</th> -->
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Last Login</th>
                          <th style="padding-left: 5px !important; padding-right: 2px !important" class="status-log-email">Status</th>
                          <th style="padding-left: 5px !important; padding-right: 2px !important">Action</th>
                      </tr>
                  </thead>
                  <tbody *ngIf=(this.coachs)>
                      <tr class="text-align: center;" *ngFor="let row of filteredClients; let i = index">
                          <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                          <td class=" image-width">
                              <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                              <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                          </td>
                          <td class="status-log-email">{{ row.first_name }} {{row.last_name}}</td>
                          <td  class="padding-l wid-client-mob-orgina">{{ row.mobile }}</td>
                          <td class=" padding-l email-width status-log-email wid-client-mob-orgina " style="padding-right: 5px !important;">{{ row.email }}</td>
                          <td class="status-log-email padding-l"> {{row.last_login | datetime:'DD-MMM-YY'}}</td>
                          <td class="status-log-email">  <select [value]="row.status" (change)="updateUserStatus(row.user_id)" [disabled]="row.access_level == 'admin' || row.org_id == motus_org_id" [id]="'status_'+row.id">
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                              </select></td>
                          <td>
                              <i class="la la-envelope text-success hoverclass" (click)="sendMail(row.user_id,row.email,row.mobile)" aria-label="Email"></i>
                              <button type="button" [disabled]="row.baselineIntakeForm == null" (click)="editClient(row.id, baselineintakeForm)" class="btn btn-sm" style="border: 0px !important;"> 
                                  <span [style.cursor]="row.baselineIntakeForm == null ? 'not-allowed' : 'pointer'">
                                      <i *ngIf="row.baselineIntakeForm == null" class="la la-file-text  text-light font-weight-bold newclass"></i>
                                      <i *ngIf="row.baselineIntakeForm != null" class="la la-file-text font-weight-bold newclass icon-success"></i> 
                                  </span>
                              </button>
                              <i (click)="editClient(row.id,Edit)" *ngIf="row.org_id != motus_org_id" class="la la-pencil-square-o text-success hoverclass mr-1" aria-hidden="true"></i>
                              <i *ngIf="row.org_id == motus_org_id" class="la la-pencil-square-o text-light mr-1" aria-hidden="true"></i>

                              <i (click)="deleteUser(row.user_id)" *ngIf="row.org_id != motus_org_id" class="la la-trash text-danger hoverclass mr-1" aria-hidden="true"></i>

                              <i class="la la-trash text-light mr-1" *ngIf="row.org_id == motus_org_id" aria-hidden="true"></i>
                              <img src="assets/images/FFD.png" alt="image" style="width: 30px; height:15px;" (click)="redirectFfd(row);openCreateForm(ClientFfd)" class="hoverclass" />
                          </td>
                      </tr>
                  </tbody>
                  <tbody *ngIf="this.showTable == true" >
                      <tr class="text-align: center;" *ngFor="let row of this.coachs | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index">
                          <td  class="id-width">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                          <td class=" image-width">
                              <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="">
                              <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                          </td>
                          <td class="status-log-email">{{ row.first_name }} {{row.last_name}}</td>
                          <td  class="padding-l wid-client-mob-orgina">{{ row.mobile }}</td>
                          <td class=" padding-l email-width status-log-email wid-client-mob-orgina " style="padding-right: 5px !important;">{{ row.email }}</td>
                          <td class="status-log-email padding-l">  {{row.last_login | datetime:'DD-MMM-YY'}}</td>
                          <td>
                            <select [value]="row.status" (change)="updateUserStatus(row.user_id)" [disabled]="row.access_level == 'admin' " [id]="'status_'+row.id">
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                            </select>
                          </td>
                          <td>

                            <i (click)="editCoach(row.id,Edit)" class="la la-pencil-square-o text-success hoverclass mr-1" aria-hidden="true"></i>
                            <i class="la la-trash text-light " aria-hidden="true" *ngIf="row.access_level == 'admin'"></i>

                            <i (click)="deleteUser(row.user_id)" class="la la-trash text-danger hoverclass" aria-hidden="true" *ngIf="row.access_level != 'admin'"></i>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <!-- Pagination -->
               <div  class="row" style="background: #727e8e;color: #ededed; margin: 0px !important;">
                  <div class="col-6">
                      <h6 class="total-entries text-style" style="color: #ededed">Total Entries: {{ totalItems }}</h6>
                  </div>
                  <div *ngIf="this.showTable == true" class="col-6" style="justify-content: end;display: grid;">
                      <ngb-pagination
                      [(page)]="currentPage"
                      [pageSize]="itemsPerPage"
                      [collectionSize]="totalItems"
                      (pageChange)="onPageChange($event)"
                      aria-label="Default pagination"
                      class="custom-pagination"
                    ></ngb-pagination>
                  </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12" *blockUI="'zeroConfiguration'; message: 'Loading'">
                  <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
                      <ng-container mCardHeaderTitle>
                      </ng-container>
                      <ng-container mCardBody>
                          <div class="card-dashboard">
                              <div style="overflow-x: scroll !important; scroll-behavior: smooth !important; width: 100%; ">
                                <ngx-datatable class="bootstrap table-bordered width-fit-content " [limit]="10" [rows]="coachs" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50"     >
                                  <ngx-datatable-column name="Id" [sortable]="false" [flexGrow]="1" [minWidth]="30" [maxWidth]="40">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
                                          {{rowIndex + 1}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Image" [sortable]="false" [flexGrow]="1" [minWidth]="90" [maxWidth]="110" >
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                          <img *ngIf="row.image!=null && row.image != 'null' " class="rounded-circle" src="{{row.image}}" alt="" style="width: 30px; height: 30px;" />
                                          <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="" style="width: 30px; height: 30px;" />
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Name" [draggable]="true" [sortable]="true" [flexGrow]="1" prop="first_name" [flexGrow]="1" [minWidth]="200" [maxWidth]="230">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                          {{row.first_name + ' ' +row.last_name| titlecase}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Mobile" [flexGrow]="1" [minWidth]="fit-content">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                          {{row.mobile}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Email" [flexGrow]="1" [minWidth]="220">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                          {{row.email}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Last Login" [draggable]="true" [sortable]="true" [flexGrow]="1" prop="last_login" [minWidth]="200" [minWidth]="210">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                          {{row.last_login | datetime:'DD-MMM-YY'}}
                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Status" [sortable]="false" [flexGrow]="1" [minWidth]="90">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                          <select [value]="row.status" (change)="updateUserStatus(row.user_id)" [disabled]="row.access_level == 'admin' " [id]="'status_'+row.id">
                                          <option value="active">Active</option>
                                          <option value="inactive">Inactive</option>
                                        </select>

                                      </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Action" [sortable]="false" [flexGrow]="1" [minWidth]="90">
                                      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">

                                          <i (click)="editCoach(row.id,Edit)" class="la la-pencil-square-o text-success hoverclass mr-1" aria-hidden="true"></i>
                                          <i class="la la-trash text-light " aria-hidden="true" *ngIf="row.access_level == 'admin'"></i>

                                          <i (click)="deleteUser(row.user_id)" class="la la-trash text-danger hoverclass" aria-hidden="true" *ngIf="row.access_level != 'admin'"></i>
                                      </ng-template>
                                  </ngx-datatable-column>
                              </ngx-datatable></div>
                            
                          </div>
                      </ng-container>
                  </m-card>
              </div>
          </div> -->
            <!-- Table -->
          
            </section>
            <section id="form-components">

                <!-- Modal -->
                <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Add Coach</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="userInfo" (ngSubmit)="onProjectInfoSubmit()" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                      <div *ngIf=" f.first_name.errors.required">
                                        First Name is required</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" formControlName="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                      <div *ngIf="f.last_name.errors.required">
                                        Last Name is required</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" formControlName="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                      <div *ngIf="f.email.errors.required">Email
                                        is required</div>
                                      <div *ngIf="f.email.errors.email">Email must
                                        be a valid email address</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" formControlName="mobile" placeholder="Mobile No." [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" (keypress)="keyPress($event)" maxlength="12" minlength="10" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                      <div *ngIf="f.mobile.errors.required">Mobile No.
                                        is required</div>
                                      <div *ngIf="f.mobile.errors.minlength">Mobile No. must be 10 digits long</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4 d-flex  align-items-center">
                                            <div>
                                                <input type="checkbox" id="as_admin" formControlName="as_admin" /> &nbsp;
                                                <label for="as_admin">Coach as admin</label>

                                            </div>

                                        </div>
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput3">Password *</label>
                                    <input type="password" id="projectinput3" class="form-control" formControlName="password"
                                      placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.password.errors.required">Password
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput3">Confirm Password *</label>
                                    <input type="password" id="projectinput3" class="form-control" formControlName="password_confirmation"
                                      placeholder="Confirm Password " [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password_confirmation.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.password_confirmation.errors.required">Password Confirmation
                                        is required</div>
                                    </small>
                                  </div>
                                </div> -->
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput6">Company Name *</label>
                                    <input type="text" id="company_name" class="form-control" formControlName="company_name"
                                      placeholder="Company Name" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.company_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.company_name.errors.required">Company Name
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="title">Title *</label>
                                    <input type="text" id="title" class="form-control" formControlName="title"
                                      placeholder="Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                                       />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.title.errors.required">Title
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="division">Division *</label>
                                    <input type="text" id="division" class="form-control" formControlName="division"
                                      placeholder="Division" [ngClass]="{ 'is-invalid': submitted && f.division.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.division.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.division.errors.required">Division
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="reportees">Reportees *</label>
                                    <input type="text" id="reportees" class="form-control" formControlName="reportees"
                                      placeholder="Reportees" [ngClass]="{ 'is-invalid': submitted && f.reportees.errors }"
                                      />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.reportees.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.reportees.errors.required">Reportees
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="dob">Date of birth *</label>
                                    <input type="date" id="dob" class="form-control" formControlName="dob"
                                      placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" [max]="today_date"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.dob.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.dob.errors.required">Date of birth
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="work_anniversary">Work anniversary *</label>
                                    <input type="date" id="work_anniversary" class="form-control" formControlName="work_anniversary"
                                      placeholder="Work anniversary" [ngClass]="{ 'is-invalid': submitted && f.work_anniversary.errors }"
                                      />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.work_anniversary.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.work_anniversary.errors.required">Work anniversary
                                        is required</div>
                                    </small>
                                  </div>
                                </div> -->
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                                    <input type="file" id="file" style="margin-left: 3px;"
                                      (change)="onFileSelected($event)" accept="image/*">
                                    <span class="file-custom"></span>
                                  </label>
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" [alt]="userInfo.value.name" class="img-fluid mb-2 rounded">
                                            </div>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                    <div *ngIf="f.image.errors.required">Image
                                      is required</div>
                                  </small> -->
                                        </div>
                                    </div>


                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary">
                                <i class="la la-check"></i> Submit
                              </button> -->

                            </form>
                        </div>

                    </div>
                </ng-template>

                <ng-template class="modal text-left" #Edit let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Edit Coach</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form #edit_coach_form="ngForm" (ngSubmit)="updateCoach(coach,coach.user_id)" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" placeholder="First Name" [(ngModel)]="coach.first_name" name="first_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf=" f.first_name.errors.required">
                                        First Name is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" placeholder="Last Name" [(ngModel)]="coach.last_name" name="last_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.last_name.errors.required">
                                        Last Name is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" placeholder="E-mail" [(ngModel)]="coach.email" name="email" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.email.errors.required">Email
                                        is required</div>
                                      <div *ngIf="f.email.errors.email">Email must
                                        be a valid email address</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" placeholder="Mobile No." (keypress)="keyPress($event)" [(ngModel)]="coach.mobile" name="mobile" maxlength="12" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.mobile.errors.required">Mobile No.
                                        is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput3">Password *</label>
                                    <input type="password" id="projectinput3" class="form-control" formControlName="password"
                                      placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.password.errors.required">Password
                                        is required</div>
                                    </small>
                                  </div>
                                </div> 
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput6">Company Name *</label>
                                    <input type="text" id="company_name" class="form-control" 
                                      placeholder="Company Name" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" [(ngModel)]="coach.company_name" name="company_name"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.company_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.company_name.errors.required">Company Name
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="title">Title *</label>
                                    <input type="text" id="title" class="form-control" 
                                      placeholder="Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                                      [(ngModel)]="coach.title" name="title"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.title.errors.required">Title
                                        is required</div>
                                    </small>
                                  </div>
                                </div>-->
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Status*</label>
                                                <select id="projectinput5" class="form-control" name="status" [(ngModel)]="coach.status" [disabled]="coach.access_level == 'admin' ">
                                      <option disabled>--Select--</option>
                                      <option value="active">Active</option>
                                      <option value="inactive">Inactive</option>
                                    </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.status.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.status.errors.required">
                                        Status is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4"> </div>
                                        <div class="col-md-4 d-flex  align-items-center">
                                            <div>
                                                <input type="checkbox" id="as_admin" [(ngModel)]="coach.as_admin" name="as_admin" [disabled]="coach.access_level == 'admin' " /> &nbsp;
                                                <label for="as_admin" style="font-weight: 700;">Coach as admin</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="division">Division *</label>
                                    <input type="text" id="division" class="form-control" name="division"
                                      placeholder="Division" [ngClass]="{ 'is-invalid': submitted && f.division.errors }" [(ngModel)]="coach.division"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.division.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.division.errors.required">Division
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="reportees">Reportees *</label>
                                    <input type="text" id="reportees" class="form-control" 
                                      placeholder="Reportees" [ngClass]="{ 'is-invalid': submitted && f.reportees.errors }"
                                      [(ngModel)]="coach.reportees" name="reportees"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.reportees.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.reportees.errors.required">Reportees
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="dob">Date of birth *</label>
                                    <input type="date" id="dob" class="form-control" 
                                      placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" [(ngModel)]="coach.dob" name="dob"[max]="today_date"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.dob.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.dob.errors.required">Date of birth
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="work_anniversary">Work anniversary *</label>
                                    <input type="date" id="work_anniversary" class="form-control" 
                                      placeholder="Work anniversary" [ngClass]="{ 'is-invalid': submitted && f.work_anniversary.errors }"
                                      [(ngModel)]="coach.work_anniversary" name="work_anniversary"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.work_anniversary.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.work_anniversary.errors.required">Work anniversary
                                        is required</div>
                                    </small>
                                  </div>
                                </div> -->
                                    </div>
                                    <div class="row col-md-4 mt-1">
                                        <label class="pl-0">Upload Image</label>
                                        <label id="projectinput7" class="file center-block pl-0">
                                  <input type="file" id="file" 
                                    [ngClass]="{ 'is-invalid': submitted && f.image.errors }" style="margin-left: 3px;"
                                    (change)="onFileSelected($event)" accept="image/*">
                                  <span class="file-custom"></span>
                                </label>
                                        <div class="col-4 pl-0" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                                            <img [src]="imageURL" alt="coach" class="img-fluid mb-2 rounded">
                                        </div>
                                        <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                  <div *ngIf="f.image.errors.required">Image
                                    is required</div>
                                </small> -->
                                    </div>


                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary">
                                <i class="la la-check"></i> Submit
                              </button> -->

                            </form>
                        </div>

                    </div>
                </ng-template>
            </section>
        </div>
    </div>
</div>